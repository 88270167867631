.academyInfo{
    padding-top: 50px;
    color: $black;
    font-size: 1.2rem;

    .futureText {
        align-content: center;
        background-color: rgba(255,255,255,0.5);
    }
    
    .imageCol{
        align-content: center;
        justify-content: center;
    }

    #curriculum {
        padding-top: 100px;
    }

    .mainLogo {
        align-content: center;
        border-radius: 50%;
    }

    .imageData {
        padding-bottom: 100px;
    }

    .futureInnovationsImage{
        align-content: center;
        border-radius: 50%;
        width: 80%;
    }

    .academyPrograms {
        padding-top: 50px;
    }
}