.center {
    padding-top: 100px;
    padding-bottom: 100px;
    color: white;
    font-size: 1.3rem;
    font-weight: 600;

    #thecenter {
        padding-bottom: 30px;
    }

    .text{
        padding-top: 10px;
        font-weight: 400;
        align-content: center;
    }
    
    .centerRow {
        background-color: rgba(96,113,150, 0.5);
    }

    .image {
        padding-bottom: 20px;
    }
}