.aboutus {
    padding-top: 50px;
    color: $black;
    font-size: 1.2rem;
    font-weight: 600;

    #aboutus {
        padding-bottom: 50px;
    }

    .text{
        padding-top: 10px;
        font-weight: 400;
    }

    .firstboardofdirectors {
        padding-bottom: 100px;
    }

    .secondboardofdirectors {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    
    .imageCol {
        display: flex;
        
        .imageDiv {
            align-content: center;
        }
    }

    .galleryRow {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}