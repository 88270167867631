@import "./Styles//Header.scss";
@import "./Styles/Variables.scss";
@import '~bootstrap/scss/bootstrap';
@import "./Styles/Mission.scss";
@import "./Styles/AboutUs.scss";
@import "./Styles/Center.scss";
@import "./Styles/MainBlurb.scss";
@import "./Styles/Footer.scss";
@import "./Styles/Gallery.scss";
@import "./Styles/Contact.scss";
@import "./Styles/Programs.scss";
@import "./Styles/AcademyInfo.scss";
@import "./Styles/WaysToHelp.scss";
@import "./Styles/CenterInfo.scss";

.App {
  text-align: center;
  font-family: $sedan;
}

