.vision {
    padding-top: 150px;
    color: $red;
    font-size: 1.1rem;
    font-weight: 600;

    #theVision {
        padding-bottom: 30px;
    }

    .visionRow{
        padding-top: 10px;
        background-color: rgba(239, 229, 91, 0.5);
    }

    .text{
        padding-top: 10px;
        align-content: center;
        font-weight: 400px;
        font-size: 1.5rem;
    }

    .visionSpan {
        padding-bottom: 10px;
    }

    .mainLogo {
        align-content: center;
        border-radius: 50%;
    }
}