#contact {
    padding-top: 80px;

    .contactRow {
        padding-top: 100px;
    }

    .text {
        padding: 1rem 0 0;
        color: $blue;

        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }
    }
}