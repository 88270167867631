@import './Variables.scss';

.header {
    
    .navBarText {
        padding: 15px;
        text-wrap: nowrap;
    }

    .navRow {
        padding: 5px;
    }
}