.allServices {
    padding-top: 50px;
    color: $black;
    font-size: 1.2rem;
    
    .programs {
        padding-bottom: 30px;
        padding-top: 50px;
    }
    
    .kindness {
        font-size: 2rem;
    }
    
    .textPlug {
        padding-bottom: 50px;
    }
    
    .bioImage{
        border-radius: 50%;
        padding-bottom: 30px;
    }
    
    .programsSection{
        padding-top: 200px;
    }
    
    .imageCol {
        align-content: center;
    }

    .plugText {
        color: $black;
    }

    .plugLink {
        color: $red;
    }

    .programInfoSection {
        padding-bottom: 50px;
    }

    .enrollmentEligibility {
        padding-top: 50px;
    }
}
