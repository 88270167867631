.MainBlurb {
    padding-top: 200px;
    padding-bottom: 100px;
    height: 100%;
    display: flex;

    .mainMission {
        display: flex;
        align-items: flex-end;
        
        .mainParagraph {
            background-color: rgb(255, 0, 0, .2);
            color: white;
            font-size: 1.5rem;
        }
    }
}